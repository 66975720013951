import * as Sentry from "@sentry/react"
import React, { Suspense, lazy, useContext } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import LoadingIndicator from '../components/common/LoadingIndicator'
import { AuthContext } from '../contexts/AuthContext'
import Playground from "../views/Playground"

const Account = lazy(() => import('../views/Account'))
const Attempt = lazy(() => import('../views/Attempt'))
const Confirmation = lazy(() => import('../views/Confirmation'))
const CreatePassword = lazy(() => import('../views/CreatePassword'))
const Dashboard = lazy(() => import('../views/Dashboard'))
const Http404 = lazy(() => import('../views/errors/Http404'))
const Login = lazy(() => import('../views/Login'))
const ResetPassword = lazy(() => import('../views/ResetPassword'))
const VulnDisclosureProgram = lazy(() => import('../views/VulnDisclosureProgram'))
const VDPReport = lazy(() => import('../views/VDPReport'))
const BugReport = lazy(() => import('../views/BugReport'))
const FeedbackForm = lazy(() => import('../views/FeedbackForm'))
const LabInstance = lazy(() => import('../views/LabInstance'))

const AppRoutes = () => {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <Suspense fallback={<LoadingIndicator />}>
      <SentryRoutes>
        <Route path="/" element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        } />
        <Route path="/account" element={
          <RequireAuth>
            <RedirectBanned>
              <Account />
            </RedirectBanned>
          </RequireAuth>
        } />
        <Route path="/attempt">
          <Route path=":attemptId" element={
            <RequireAuth>
              <RedirectBanned>
                <Attempt />
              </RedirectBanned>
            </RequireAuth>
          } />
        </Route>
        <Route path="/training/lab_instance">
          <Route path=":instanceId" element={
            <RequireAuth>
              <RedirectBanned>
                <LabInstance />
              </RedirectBanned>
            </RequireAuth>
          } />
        </Route>
        <Route path="/create_password" element={
          <RequireAuth>
            <RedirectBanned>
              <CreatePassword />
            </RedirectBanned>
          </RequireAuth>
        } />
        <Route path="/bug_report" element={
          <RequireAuth>
            <RedirectBanned>
              <BugReport />
            </RedirectBanned>
          </RequireAuth>
        } />
        <Route path="/feedback" element={
          <RequireAuth>
            <RedirectBanned>
              <FeedbackForm />
            </RedirectBanned>
          </RequireAuth>
        } />
        <Route path="/login" element={<Login />} />
        <Route path="/reset_password" element={<ResetPassword />} />
        <Route path="/activation/:uid/:token" element={<Confirmation />} />
        <Route path="/password/reset/confirm/:uid/:token" element={<Confirmation />} />
        <Route path="/vdp" element={<VulnDisclosureProgram />} />
        <Route path="/vdp_form" element={<VDPReport />} />
        <Route path="/playground" element={<Playground />} />
        <Route path="*" element={<Http404 />} />
      </SentryRoutes>
    </Suspense>
  );
};

const RequireAuth = ({ children, redirectTo = "/login" }) => {
  const location = useLocation();
  const authContext = useContext(AuthContext);

  const isAuthenticated = authContext.isAuthenticated();

  return isAuthenticated ? children : <Navigate replace to={redirectTo} state={{ from: location }} />
};

const RedirectBanned = ({ children, redirectTo = "/" }) => {
  const location = useLocation()
  const authContext = useContext(AuthContext)

  const isBanned = authContext.isBanned()

  return !isBanned ? children : <Navigate replace to={redirectTo} state={{ from: location }} />
}

export default AppRoutes;