import React from 'react'
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';

export default function Footer() {
  const useStyles = makeStyles({
    footer: {
      bottom: 0,
      width: '100%',
      position: "flex",
      zIndex: -1,
    }
  });

  const styles = useStyles();

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  return (
    <Box
      component='footer'
      className={styles.footer}
      sx={{
        flexGrow: 1,
        p: 2,
      }}
    >
      <Grid container spacing={3} justifyContent="space-between" alignItems="center">
        <Grid>
          <Item>
            <Copyright />
          </Item>
        </Grid>
        <Grid container spacing={2}>
          <Grid>
            <Item>
              <Typography variant="subtitle2" color="text.secondary" align="center">
                <Link href='/vdp' sx={{ m: 1 }}>Vulnerability Disclosure Program</Link>
              </Typography>
            </Item>
          </Grid>
          <Grid>
            <Item>
              <Typography variant="subtitle2" color="text.secondary" align="center">
                <Link href='/vdp_form' sx={{ p: 1 }}>Report a Vulnerability</Link>
              </Typography>
            </Item>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

const Copyright = () => {
  return (
    <Typography
      variant='body2'
      color='text.secondary'
      align='center'
      sx={{ p: .5 }}
    >
      {'Copyright © '}
      <Link
        color='inherit'
        href='https://tcm-sec.com'
      >
        TCM Security, Inc.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};